<template>
  <div class="p-container">
    <div class="p-title">
      <router-link :to="{name:'detail',params:{permalink:post.permalink}}">
        {{ post.title }}
      </router-link>
    </div>
    <div class="p-front">
      <font-awesome-icon :icon="['fas', 'folder-open']" class="p-front-item"></font-awesome-icon>
      <span class="p-front-item">
        <router-link :to="{name:'home',query:{cat:post.category}}">
          {{post.category}}
        </router-link>
      </span>
      <font-awesome-icon :icon="['fas', 'tags']" class="p-front-item"></font-awesome-icon>
      <span v-for="(item,index) in post.tags" :key="index" class="p-front-item">
        <router-link :to="{name:'home',query:{tag:item}}">
          #{{item}}
        </router-link>
      </span>
      <span style="float: right;">
        <font-awesome-icon :icon="['fas', 'calendar-alt']" class="p-front-item"></font-awesome-icon>
      <span class="p-front-item">{{post.date.substr(0,10)}}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostItem",
  props: ["post"],
}
</script>

<style scoped>
.p-container {
  margin: 20px 0;
  padding: 10px;
  box-shadow:2px 2px 5px #000;
}
.p-container:hover{
  box-shadow:2px 2px 12px #000;
}
.p-title {
  margin: 5px;
  font-size: 19px;
}
a {
  color: #494949;
}
a:hover {
  color: #777;
}
.p-front {
  margin: 10px 5px;
  color: #777777;
}
.p-front-item{
  font-size: 13px;
  margin-right: 5px;
}
</style>
