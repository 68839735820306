<template>
  <div>
    <div class="columns" style="height: 100%;">
      <div class="column is-1"></div>
      <div class="column">
        <PostItem v-for="(post,index) in posts" :key="index" :post="post"></PostItem>
      </div>
      <div class="column is-1"></div>
    </div>
  </div>
</template>

<script>
import PostItem from "../components/PostItem";
export default {
  name: "HomePage",
  components: {
    PostItem,
  },
  data(){
    return{
      "curTag":"",
      "curCat":"",
      "curKey":"",
      "allPosts":[],
      "posts":[],
      "tags":[],
      "cats":[],
    }
  },
  methods:{
    getData(filterTag,filterCat,filterKey){
      let usePosts=this.allPosts.slice(0,this.allPosts.length);
      if(filterTag){
        let that=this;
        usePosts=usePosts.filter(function (post){
          return post.tags.indexOf(that.curTag)>=0;
        })
      }
      if(filterCat){
        let that=this;
        usePosts=usePosts.filter(function (post){
          return post.category===that.curCat;
        })
      }
      if(filterKey){
        let that=this;
        usePosts=usePosts.filter(function (post){
          // TODO 多个关键词与和或的查询逻辑
          return JSON.stringify(post).indexOf(that.curKey)!==-1
              || JSON.stringify(post).indexOf(that.curKey.toUpperCase())!==-1
              || JSON.stringify(post).indexOf(that.curKey.toLowerCase())!==-1;
        })
      }
      this.posts=usePosts;
    },
    pageChange(page){
      this.curPage=page;
      // 深拷贝才能修改url参数
      let newParams= JSON.parse(JSON.stringify(this.$route.query));
      newParams.page = page;
      this.$router.replace({
        query: newParams,
      })
      this.getData();
    },
    refreshData(){
      let filterTag=false;
      let filterCat=false;
      let filterKey=false;
      if(this.$route.query.tag){
        this.curTag=this.$route.query.tag;
        filterTag=true;
      }else{
        this.curTag="";
      }
      if(this.$route.query.cat){
        this.curCat=this.$route.query.cat;
        filterCat=true;
      }else{
        this.curCat="";
      }
      if(this.$route.query.key){
        this.curKey=this.$route.query.key;
        filterKey=true;
      }else{
        this.curKey="";
      }
      this.getData(filterTag,filterCat,filterKey);
    },
  },
  created() {
    // 静态文件放public，默认从public找
    this.$axios.get("/data.json").then((resp) => {
      this.allPosts=resp.data;
      this.refreshData();
    })
  },
  watch:{
    $route(){
      this.refreshData();
    }
  },
}
</script>

<style scoped>
</style>
